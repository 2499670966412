// documenti-renderer.component.ts
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DocumentiService } from 'app/server/services/polizze/documenti.service';

@Component({
  selector: 'app-documenti-renderer',
  template: `
    <div *ngFor="let documento of params.value">
      <a (click)="apriDocumento(documento)" class="btn btn-secondary">{{ documento.nomefile }}</a>
    </div>
  `,
  styles: [`
  a {
    font-size: 12px;
    padding: 6px
  }
`],
})
export class DocumentiRendererComponent implements ICellRendererAngularComp {
  params: any;

  constructor ( private documentiService: DocumentiService) {

  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  apriDocumento(documento: any): void {

    this.documentiService.getDoc(documento.guiddoc).subscribe(res => {
        const blobUrl = URL.createObjectURL(this.b64toBlob(res.bindata, res.mimetype));
            
        window.open(blobUrl, '_blank');
    })
  }

  b64toBlob(b64Data, contentType='', sliceSize=512){
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
}