import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { AuthResponse, CommonResponse, LoginResponse, OneTimeToken, User } from 'app/server/models';
import { DialogService } from '../helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreMenu } from '../../../@core/types';
import { UserTypeService } from './user-type.service';
import { Utente } from '../models/admin/utente';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	// public
	public currentUser: Observable<User>;
	public refreshingToken: boolean = false;
	public userData: User | Utente;
	// _http: HttpClient;

	// private
	private currentUserSubject: BehaviorSubject<string>;
	public currentUserObj: BehaviorSubject<User>;

	/**
	 *
	 * @param {HttpBackend} handler
	 * @param {HttpClient} _http
	 * @param {DialogService} _dialogService
	 * @param {Router} _router
	 * @param {ActivatedRoute} _route
	 */
	constructor(
		handler: HttpBackend,
		private _http: HttpClient,
		private _dialogService: DialogService,
		private _router: Router,
		private _route: ActivatedRoute,
		private _userTypeService: UserTypeService
	) {
		// this._http = new HttpClient(handler);
		this.currentUserSubject = new BehaviorSubject<string>(JSON.parse(sessionStorage.getItem('currentUser')));
		this.currentUserObj = new BehaviorSubject<User>(null);
		this.currentUser = this.currentUserObj.asObservable();
		if (this.currentUserValue && this._router.url) {
			this.getCurrentUser();
		}
	}

	// getter: currentUserValue
	public get currentUserValue(): string {
		return this.currentUserSubject.value;
	}

	goToSSO(tenant_uuid: string) {
		window.location.href = `${environment.baseUrl}/saml2/${tenant_uuid}/login`;
		return;
	}

	SSOExist() {
		return this._route.snapshot.queryParams['one_time_token'];
	}

	login(request: { email: string; password: string }): Observable<LoginResponse> {
		if (this._userTypeService.userTypeObj.value === 'azienda' || this._userTypeService.userTypeObj.value === 'utente') {
			return this._http.post<LoginResponse>(`/auth/login`, request).pipe(
				map((data: LoginResponse) => {
					// login successful if there's a jwt token in the response

					if (data) {
						// store user details and jwt token in local storage to keep user logged in between page refreshes
						sessionStorage.setItem('currentUser', JSON.stringify(data.tkn));
						// Display welcome toast!
						setTimeout(() => {
							this._dialogService.showToast('success', 'Quasi fatto!', 'Codice di verifica inviato. Controlla la tua email per continuare.');
						}, 1000);

						// notify
						this.currentUserSubject.next(data.tkn);
						// this.getCurrentUser();
					}
					return data;
				})
			);
		} else {
			return this._http.post<CommonResponse<OneTimeToken>>(`/auth/login`, request).pipe(
				map((data: CommonResponse<OneTimeToken>) => {
					// login successful if there's a jwt token in the response

					if (data.data.access_token) {
						// store user details and jwt token in local storage to keep user logged in between page refreshes
						sessionStorage.setItem('currentUser', JSON.stringify(data.data.access_token));
						// Display welcome toast!
						// setTimeout(() => {
						// 	this._dialogService.showToast('success', this._translateService.instant('Benvenuto!'), this._translateService.instant('Login effettuato correttamente'));
						// }, 1000);

						// notify
						this.currentUserSubject.next(data.data.access_token);
						this.getCurrentUser();
					}
					return undefined;
				})
			);
		}
	};

	checkLoginOTP(request: {loginotp: string}): Observable<AuthResponse> {
		return this._http.post<AuthResponse>('/auth/checkloginotp', request).pipe(
			map((data: AuthResponse) => {
				if (data.result === 'OK') {
					this.getCurrentUser();
					return data;
				} else {
					return data
				}
			})
		)
	}

	loginSaml2(oneTimeToken: OneTimeToken): Observable<string> {
		// auth/retrieve/jwt/ott/${oneTimeToken} jwt e oneTimeToken: access_token è il token, token_type è bearer o ott, expires_in
		return this._http.get<CommonResponse<OneTimeToken>>(`/auth/retrieve/jwt/ott/${oneTimeToken}`).pipe(
			map((data: CommonResponse<OneTimeToken>) => {
				// login successful if there's a jwt token in the response
				if (data.data.access_token) {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					sessionStorage.setItem('currentUser', JSON.stringify(data.data.access_token));
					// Display welcome toast!
					// setTimeout(() => {
					// 	console.log(2)
					// 	this._dialogService.showToast('success', this._translateService.instant('Benvenuto!'), this._translateService.instant('Login effettuato correttamente'));
					// }, 1000);

					// notify
					this.currentUserSubject.next(data.data.access_token);
					this.getCurrentUser();
				}
				return undefined;
			})
		);
	}

	/**
	 * User logout
	 *
	 */
	logout() {
		if (this._userTypeService.userTypeObj.value === 'admin') {
			sessionStorage.removeItem('currentUser');
			this.currentUserSubject.next(null);
			window.location.href = '/admin';
		} else {

			this._http.get('/auth/logout').subscribe(res => {
				sessionStorage.removeItem('currentUser');
				this.currentUserSubject.next(null);
				window.location.href = '/login';
			})
		}
	}

	askNewUser(userData: { ragsoc: string; pivacf: string; referente: string; tel: string; mail: string }): Observable<AuthResponse> {
		return this._http.post<AuthResponse>('/auth/asknewuser', userData);
	}

	checkForgetPasLink(token: string): Observable<boolean> {
		return this._http.post<boolean>('/auth/checkforgetpasslink', { tkn: token });
	}

	setForgetPass(newPassword: { tkn: string; pwd: string; conferma_password?: string }): Observable<AuthResponse> {
		return this._http.post<AuthResponse>('/auth/setforgetpass', newPassword);
	}

	changePassword(data: {opwd: string, npwd: string}): Observable<AuthResponse> {
		return this._http.post<AuthResponse>('/auth/changepass', data)
	}

	startForgetPassProcedure(data: {usr: string}): Observable<any> {
		return this._http.post<any>('/auth/forgetpass', data);
	}

	refreshToken() {
		this.refreshingToken = true;
		return this._http
			.post<CommonResponse<OneTimeToken>>(
				`/auth/refresh`,
				{},
				{
					headers: {
						Authorization: `Bearer ${this.currentUserValue}`,
					},
				}
			)
			.pipe(
				map((data: CommonResponse<OneTimeToken>) => {
					// login successful if there's a jwt token in the response
					if (data.data) {
						// store user details and jwt token in local storage to keep user logged in between page refreshes
						sessionStorage.setItem('currentUser', JSON.stringify(data.data.access_token));

						// notify
						this.currentUserSubject.next(data.data.access_token);
						this.getCurrentUser();
					}

					this.refreshingToken = false;
					return undefined;
				})
			);
	}

	menuFilter(menu: CoreMenu[], role_id: number) {
		// if (this.currentUserObj.value.fiscal_code) {
		//   menu.find((res) => res.id === 'bustepaga_cud').children[0].url = `request/payroll/${this.currentUserValue}`;
		// } else {
		//   menu = menu.filter((res) => res.id !== 'bustepaga_cud');
		// }

		return menu.filter(item => this.itemMenuFilter(item, role_id));
	}

	getLoggedUser(): Observable<User> {
		if (this._userTypeService.userTypeObj.value === 'azienda' || this._userTypeService.userTypeObj.value === 'utente') {
			return this._http
				.get<User>(`/user/getprofile`, {
					headers: {
						Authorization: `Bearer ${this.currentUserValue}`,
					},
				})
				.pipe(map(res => res));
		} else {
			return this._http
				.get<CommonResponse<User>>(`/auth/me`, {
					headers: {
						Authorization: `Bearer ${this.currentUserValue}`,
					},
				})
				.pipe(map(res => res.data));
		}
	}

	getUserDetails() {
		return this._http.get<User>('/user/getprofile');
	}

	private getCurrentUser(): void {
		this.getLoggedUser().subscribe(
			res => {
				this.currentUserObj.next(res);

				this.userData = res;
			},
			error => {
				if (error.status === 401 || error.status === 403) {
					this.logout();
					this._router.navigate(['/authentication/login']);
					// this._router.navigate(['/miscellaneous/not-authorized']);
				}
			}
		);
	}

	private itemMenuFilter(menuItem: CoreMenu, role_id: number) {
		if (menuItem.children) {
			menuItem.children = menuItem.children.filter(item => this.itemMenuFilter(item, role_id));
		}
		if (menuItem.roles) {
			return menuItem.roles.includes(role_id);
		} else {
			return true;
		}
	}

	isAdmin() {
		if (this.userData) return this.userData?.role === 'MASTER' ? true : false;
	}

	isStandard() {
		return this.userData?.role === 'STANDARD' ? true : false;
	}
}
