import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as Waves from 'node-waves';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';

import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from './server/services';
import { CoreMenu } from '../@core/types';
import { menu } from './menu/menu';
import { adminMenu } from './menu/admin-menu';
import { locale as menuEnglish } from 'app/menu/i18n/en';
import { locale as menuFrench } from 'app/menu/i18n/fr';
import { locale as menuGerman } from 'app/menu/i18n/de';
import { locale as menuPortuguese } from 'app/menu/i18n/pt';
import { TranslateService } from '@ngx-translate/core';
import { UserTypeService } from './server/services/user-type.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	coreConfig: any;
	menu: Array<CoreMenu> = [];
	adminMenu: Array<CoreMenu> = [];
	layout: 'backend' | 'frontend' = 'backend';
	appLanguage: 'en'; // Set application default language i.e fr

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {DOCUMENT} document
	 * @param {Title} _title
	 * @param {Renderer2} _renderer
	 * @param {ElementRef} _elementRef
	 * @param {CoreConfigService} _coreConfigService
	 * @param {CoreSidebarService} _coreSidebarService
	 * @param {CoreLoadingScreenService} _coreLoadingScreenService
	 * @param {CoreMenuService} _coreMenuService
	 * @param {Router} _router
	 * @param {AuthenticationService} _authenticationService
	 * @param {CoreTranslationService} _coreTranslationService
	 * @param {TranslateService} _translateService
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _title: Title,
		private _renderer: Renderer2,
		private _elementRef: ElementRef,
		public _coreConfigService: CoreConfigService,
		private _coreSidebarService: CoreSidebarService,
		private _coreLoadingScreenService: CoreLoadingScreenService,
		private _coreMenuService: CoreMenuService,
		private _router: Router,
		private _authenticationService: AuthenticationService,
		private _coreTranslationService: CoreTranslationService,
		private _translateService: TranslateService,
		public _userTypeService: UserTypeService
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();

		this.menu = JSON.parse(JSON.stringify(menu));
		this.adminMenu = JSON.parse(JSON.stringify(adminMenu));

		// Register the menu to the menu services
		this._coreMenuService.register('main', this.menu);
		this._coreMenuService.register('admin', this.adminMenu);

		// Add languages to the translation service
		this._translateService.addLangs(['en', 'fr', 'de', 'pt']);

		// This language will be used as a fallback when a translation isn't found in the current language
		this._translateService.setDefaultLang('en');

		// Set the translations for the menu
		this._coreTranslationService.translate(menuEnglish, menuFrench, menuGerman, menuPortuguese);

		this._authenticationService.currentUser.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
			if (this._userTypeService.userTypeObj.value !== 'admin') {
				if (user && !this._authenticationService.refreshingToken) {
					this.layout = 'frontend';

					this._coreConfigService.setConfig({ layout: { type: 'horizontal', skin: 'light' } });

					this.menu = this._authenticationService.menuFilter(this.menu, user.role === 'MASTER' ? 1 : 2);

					if (this._coreMenuService.menuKeyExist('main')) {
						this._coreMenuService.unregister('main');
					}

					// Register the menu to the menu services
					this._coreMenuService.register('main', this.menu);

					// Set the main menu as our current menu
					this._coreMenuService.setCurrentMenu('main');

					// Add languages to the translation service
					this._translateService.addLangs(['en', 'fr', 'de', 'pt']);

					// This language will be used as a fallback when a translation isn't found in the current language
					this._translateService.setDefaultLang('en');

					// Set the translations for the menu
					this._coreTranslationService.translate(menuEnglish, menuFrench, menuGerman, menuPortuguese);
				}
			} else {
				//this.layout = 'backend';

				//this._coreConfigService.setConfig({ layout: { type: 'vertical', skin: 'bordered' } });
				if (this._coreMenuService.menuKeyExist('admin')) {
					this._coreMenuService.unregister('admin');
				}

				this._coreMenuService.register('admin', this.adminMenu);

				this._coreMenuService.setCurrentMenu('admin');

				// Add languages to the translation service
				this._translateService.addLangs(['en', 'fr', 'de', 'pt']);

				// This language will be used as a fallback when a translation isn't found in the current language
				this._translateService.setDefaultLang('en');

				// Set the translations for the menu
				this._coreTranslationService.translate(menuEnglish, menuFrench, menuGerman, menuPortuguese);
			}
		});
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Init wave effect (Ripple effect)
		Waves.init();

		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
			this.coreConfig = config;

			// Set application default language.
			const appLanguage = this.coreConfig.app.appLanguage || 'en';
			this._translateService.use(appLanguage);

			// Change application language? Read the ngxTranslate Fix

			// Remove default classes first
			this._elementRef.nativeElement.classList.remove('vertical-layout', 'vertical-menu-modern', 'horizontal-layout', 'horizontal-menu');
			// Add class based on config options
			if (this.coreConfig.layout.type === 'vertical') {
				this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
			} else if (this.coreConfig.layout.type === 'horizontal') {
				this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
			}

			// Navbar
			//--------

			// Remove default classes first
			this._elementRef.nativeElement.classList.remove('navbar-floating', 'navbar-static', 'navbar-sticky', 'navbar-hidden');

			// Add class based on config options
			if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
				this._elementRef.nativeElement.classList.add('navbar-static');
			} else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
				this._elementRef.nativeElement.classList.add('navbar-sticky');
			} else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
				this._elementRef.nativeElement.classList.add('navbar-floating');
			} else {
				this._elementRef.nativeElement.classList.add('navbar-hidden');
			}

			// Footer
			//--------

			// Remove default classes first
			this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

			// Add class based on config options
			if (this.coreConfig.layout.footer.type === 'footer-sticky') {
				this._elementRef.nativeElement.classList.add('footer-fixed');
			} else if (this.coreConfig.layout.footer.type === 'footer-static') {
				this._elementRef.nativeElement.classList.add('footer-static');
			} else {
				this._elementRef.nativeElement.classList.add('footer-hidden');
			}

			// Blank layout
			if (this.coreConfig.layout.menu.hidden && this.coreConfig.layout.navbar.hidden && this.coreConfig.layout.footer.hidden) {
				this._elementRef.nativeElement.classList.add('blank-page');
				// ! Fix: Transition issue while coming from blank page
				this._renderer.setAttribute(this._elementRef.nativeElement.getElementsByClassName('app-content')[0], 'style', 'transition:none');
			} else {
				this._elementRef.nativeElement.classList.remove('blank-page');
				// ! Fix: Transition issue while coming from blank page
				setTimeout(() => {
					this._renderer.setAttribute(
						this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
						'style',
						'transition:300ms ease all'
					);
				}, 0);
				// If navbar hidden
				if (this.coreConfig.layout.navbar.hidden) {
					this._elementRef.nativeElement.classList.add('navbar-hidden');
				}
				// Menu (Vertical menu hidden)
				if (this.coreConfig.layout.menu.hidden) {
					this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
				} else {
					this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
				}
				// Footer
				if (this.coreConfig.layout.footer.hidden) {
					this._elementRef.nativeElement.classList.add('footer-hidden');
				}
			}

			// Skin Class (Adding to body as it requires highest priority)
			if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
				this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
				this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
			}
		});

		// this._router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(val => {
		// 	if (val instanceof NavigationStart) {
		// 		this._coreLoadingScreenService.show();
		// 	}
		// 	if (val instanceof NavigationEnd) {
		// 		setTimeout(() => {
		// 			this._coreLoadingScreenService.hide();
		// 		}, 100);
		// 	}
		//
		// 	// Set loading state to false in both of the below events to hide the spinner in case a request fails
		// 	if (val instanceof NavigationCancel) {
		// 		setTimeout(() => {
		// 			this._coreLoadingScreenService.hide();
		// 		}, 100);
		// 	}
		// 	if (val instanceof NavigationError) {
		// 		setTimeout(() => {
		// 			this._coreLoadingScreenService.hide();
		// 		}, 100);
		// 	}
		// });

		// Set the application page title
		this._title.setTitle(this.coreConfig.app.appTitle);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebar(key): void {
		this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
	}
}
