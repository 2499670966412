import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from 'environments/environment';
import {AuthenticationService} from 'app/server/services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   */
  constructor() {
  }

  /**
   * Add server header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

   //const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser}`
        }
      });
    }

    return next.handle(request);

  }

}
