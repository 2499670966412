import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  _httpLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  httpLoading$: Observable<boolean> = this._httpLoading.asObservable();

  constructor(private _http: HttpClient) {}

  loading(): any {}
}
