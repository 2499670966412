import { AbstractControlOptions, AsyncValidatorFn, FormGroup, ValidatorFn } from '@angular/forms';

export class FormGroupPro extends FormGroup {
    data: FormData;

    constructor(
        controls,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(controls, validatorOrOpts, asyncValidator);
    }

    get formData() {
        this.data = new FormData();

        const keys = Object.keys(this.value);
        keys.forEach(key => {
            this.transformObject(key, this.value[key]);
        });

        return this.data;
    }

    private transformObject(key, value) {
        switch (typeof value) {
            case 'bigint':
                this.appendValue(key, value);
                return;
            case 'boolean':
                value = value ? 1 : 0;
                this.appendValue(key, value);
                return;
            case 'function':
                console.error("can't append function to FormData object");
                return;
            case 'number':
                this.appendValue(key, value);
                return;
            case 'object':
                if (value !== null && value !== undefined) {
                    const keysSubObj = Object.keys(value);

                    keysSubObj.forEach(keySubObj => {
                        this.transformObject(`${key}[${keySubObj}]`, value[keySubObj]);
                    });
                } else {
                    this.appendValue(key, value);
                }

                return;
            case 'string':
                this.appendValue(key, value);
                return;
            case 'symbol':
                console.error("can't append symbol to FormData object");
                return;
            case 'undefined':
                return;
            default:
                return;
        }
    }

    private appendValue(key, value) {
        if (value !== null && value !== undefined) {
            this.data.append(key, value);
        } else {
            this.data.append(key, '');
        }
    }
}
