import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserTypeService } from '../services/user-type.service';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
	constructor(private _userTypeService: UserTypeService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!request.url.startsWith('https://') && !request.url.startsWith('http://')) {
			if (this._userTypeService.userTypeObj.value === 'azienda') {
				request = request.clone({ url: `${environment.sazApiUrl}${request.url}` });
			} else if (this._userTypeService.userTypeObj.value === 'utente') {
				request = request.clone({ url: `${environment.sisApiUrl}${request.url}` });
			} else if (this._userTypeService.userTypeObj.value === 'admin') {
				request = request.clone({ url: `${environment.sadApiUrl}${request.url}` });
			}
		}

		return next.handle(request);
	}
}
