import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserTypeService {
	public userTypeObj: BehaviorSubject<string>;

	constructor() {
		this.userTypeObj = new BehaviorSubject<string>(null);

		if (localStorage.getItem('type')) {
			this.userTypeObj.next(localStorage.getItem('type'));
		}
	}

	set userType(data: string) {
		localStorage.setItem('type', data);

		this.userTypeObj.next(data);
	}

	get userType(): string | Observable<string> {
		return this.userTypeObj.value;
	}
}
