import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
	CellValueChangedEvent,
	ColDef,
	GridApi,
	GridOptions,
	GridReadyEvent,
	IRowNode,
	IsRowSelectable,
	RowNode,
	RowValueChangedEvent,
} from 'ag-grid-community';
import { AG_GRID_LOCALE_IT } from './locale/ag-grid.it';
import { Action } from './models/action';
import { ErrorService } from '../../../app/server/services/error.service';
import { DialogService } from '../../../app/server/helpers';
import { log10 } from 'chart.js/helpers';

// import {BtnCellRendererComponent} from './components/btn-cell-renderer.component';
// import {BtnCellEditRendererComponent} from './components/btn-cell-edit-renderer.component';

@Component({
	selector: 'core-table',
	templateUrl: './core-table.component.html',
	styleUrls: ['./core-table.component.scss'],
})
export class CoreTableComponent implements OnInit, OnChanges {
	@Input() tableName: string;
	@Input() columnDefs: ColDef[];
	@Input() rowData: Array<any>;
	@Input() rowSelection: 'multiple' | 'single' | 'none' = 'none';
	@Input() editType: 'fullRow' | undefined;
	@Input() actions: Array<Action> = [];
	@Input() simple: boolean = false;

	@Input() actionButtonTxt: string;
	@Input() secondButtonTxt: string;
	@Input() actionButtonClass = 'secondary';
	@Input() secondButtonClass = 'danger';
	@Input() updateOnSelect = false;
	@Input() selectedIds: Array<any> = [];
	@Input() isRowSelectable: IsRowSelectable = () => true;
	@Input() actionButtonDisabled: boolean;
	@Input() secondButtonDisabled: boolean;
	@Input() onFirstDataRendered: any = () => {};
	@Input() onRowDataUpdated: any = () => {};

	@Input() pinnedBottomRow = [];

	@Output() rowsSelected = new EventEmitter<Array<IRowNode>>();
	@Output() rowEdited = new EventEmitter<Array<RowNode>>();
	@Output() actionButtonClick = new EventEmitter();
	@Output() secondButtonActionClick = new EventEmitter();
	pageSize: number = 30;

	private gridApi!: GridApi;

	tableLanguage = AG_GRID_LOCALE_IT;

	gridOptions: GridOptions = {
		rowHeight: 30,
		suppressMenuHide: true,
		defaultColDef: {
			sortable: true,
			resizable: true,
			filter: true,
			flex: 1,
			wrapText: true,
			autoHeight: true,
		} /*,
    frameworkComponents: {
      btnCellRenderer: BtnCellRendererComponent,
      btnCellEditRenderer: BtnCellEditRendererComponent
    }*/,
		pinnedBottomRowData: this.pinnedBottomRow,
	};

	constructor(
		private _errorService: ErrorService,
		private _dialogService: DialogService
	) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  showLoadingOverlay() {
    this.gridApi.showLoadingOverlay();
  }

  showNoResultOverlay() {
    this.gridApi.showNoRowsOverlay();
  }

  hideOverlay() {
    this.gridApi.hideOverlay();
  }

  /**
   * On init
   */
  ngOnInit() {
    this.gridOptions.pinnedBottomRowData = this.pinnedBottomRow;
  }

	ngOnChanges(changes: SimpleChanges) {
		// Controllo se la variabile pinnedBottomRow è stata modificata
		if (changes.pinnedBottomRow && changes.pinnedBottomRow.currentValue) {
			if (this.gridApi) {
				this.gridApi.setGridOption('pinnedBottomRowData', this.pinnedBottomRow);
			} else {
				this.gridOptions.pinnedBottomRowData = this.pinnedBottomRow;
			}
		}
	}

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
		if (this.tableName) {
			const savedFilters = sessionStorage.getItem(this.tableName);
			if (savedFilters) {
				const savedFiltersObj = JSON.parse(savedFilters) as any;
				const quickFilter = savedFiltersObj.quickFilter;
				delete savedFiltersObj.quickFilter;
				this.gridApi.setFilterModel(savedFiltersObj);

				if (quickFilter) {
					(document.getElementById('filter-text-box') as HTMLInputElement).value = quickFilter;
					this.gridApi.setGridOption('quickFilterText', quickFilter);
				}
			}
		}

		if (this.pinnedBottomRow) {
			this.gridApi.setGridOption('pinnedBottomRowData', this.pinnedBottomRow);
		}
	}

	/*showLoading() {
    this.gridApi.showLoadingOverlay();
  }

  hideLoading() {
    this.gridApi.hideOverlay();
  }*/

	onGridSizeChanged() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
      this.gridApi.getSelectedNodes();
    }
	}

	onFilterTextBoxChanged() {
		this.gridApi.setGridOption('quickFilterText', (document.getElementById('filter-text-box') as HTMLInputElement).value);

		if (!(document.getElementById('filter-text-box') as HTMLInputElement).value) {
			this.saveFilterModel();
		}
	}

	onBtnExport() {
		const params = {
			columnSeparator: ';',
		};
		this.gridApi.exportDataAsCsv(params);
	}

	onPageSizeChanged() {
		this.gridApi.setGridOption('paginationPageSize', this.pageSize);
	}

	emitRows($event) {
		this.rowsSelected.emit(this.gridApi.getSelectedNodes());
		if (this.updateOnSelect) {
			this.updateRows();
		}
	}

	updateRows() {
		this.gridApi.applyTransaction({ update: this.rowData });
	}

	emitEditedRow($event: RowValueChangedEvent) {
		this.rowEdited.emit($event.data);
	}

	onCellValueChanged(event: CellValueChangedEvent) {
		// console.log(event);
		// console.log(
		//   'onCellValueChanged: ' + event.colDef.field + ' = ' + event.newValue
		// );
	}

	actionButtonEvent($event: Event) {
		this.actionButtonClick.emit($event);
	}

	secondButtonEvent($event: Event) {
		this.secondButtonActionClick.emit($event);
	}

	saveFilterModel() {
		if (this.tableName) {
			const savedFilterModel = this.gridApi.getFilterModel();
			savedFilterModel.quickFilter = (document.getElementById('filter-text-box') as HTMLInputElement).value;
			sessionStorage.setItem(this.tableName, JSON.stringify(savedFilterModel));
		}
	}
}
