import { Component, OnInit } from "@angular/core";
import {BaseComponent} from '../../../custom-class/base.page';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CoreCommonModule} from '../../../../@core/common.module';
import {CoreTableModule} from '../../../../@core/components';
import {FormGroupPro} from '../../../custom-class/form-group-pro';
import {FormControl, Validators} from '@angular/forms';
import { SegnalazioniService } from '../../../server/services/segnalazioni/segnalazioni.service';
import { takeUntil } from 'rxjs';
import { DialogService } from '../../../server/helpers';

@Component({
  selector: 'app-form-segnalazioni',
  templateUrl: './form-contatto.component.html',
  styleUrls: ['./form-contatto.component.scss'],
  standalone: true,
  imports: [
    CoreCommonModule,
  ]
})
export class FormContattoComponents extends BaseComponent implements OnInit {
  segnalazioneErroriForm: FormGroupPro;
  loadingForm: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private _segnalazioniService: SegnalazioniService,
    private _dialogService: DialogService,
  ) {
    super();
  }

  inviaSegnalazione() {
    this.loadingForm = true;

    this._segnalazioniService.inviaSegnalazione(this.segnalazioneErroriForm.value).subscribe({
      next: res => {
        if (res.result === 'OK') {

          this.loadingForm = false;

          this._dialogService.showToast('success', 'Inviata!', res.mess);

          this.activeModal.dismiss();

        } else {
          this.loadingForm = false;

          this._dialogService.showToast('error', 'Errore', res.valmess[0]);
        }

      },
      error: error => {
        this.loadingForm = false;
        this._dialogService.showToast('error', 'Errore', 'Si è verificato un errore durante l\'invio della segnalazione');
      },
    })
  }

  ngOnInit() {
    this.segnalazioneErroriForm = new FormGroupPro({
      msgbody: new FormControl(null, [Validators.required, Validators.minLength(3)])
    });
  }
}
