import {Component, OnDestroy} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
    template: `
    <button [class]="class" (click)="btnClickedHandler($event)">{{diplayText}}</button>
  `,
})
export class BtnCellEditRendererComponent implements ICellRendererAngularComp, OnDestroy {
    private params: any;
    diplayText: string;
    class: string;

    constructor(private _router: Router,
                private _route: ActivatedRoute) {
    }

    agInit(params: any): void {
        this.params = params;
        if (this.params.label) {
            this.diplayText = this.params.label;
        } else {
            this.diplayText = this.params.value;
        }

        if (this.params.class) {
            this.class = this.params.class;
        } else {
            this.class = 'btn btn-primary stile-btn';
        }
        this.params.url = this.params.url.replace('{field}', this.params.data[this.params.field]);
    }

    btnClickedHandler($event) {
        this.params.api.startEditingCell({
            rowIndex: this.params.rowIndex,
            colKey: this.params.colKey,
        });
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams) {
        return true;
        // set value into cell again
    }

    ngOnDestroy() {
        // no need to remove the button click handler
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}
