<ng-container *ngIf="!item.hidden">
  <!-- item.url -->

	@if (item.id !== 'invia-segnalazioni') {
		<a
			class="dropdown-item d-flex align-items-center"
			[ngClass]="item.classes"
			*ngIf="item.url && !item.externalUrl"
			[routerLink]="[item.url]"
			[target]="item.openInNewTab ? '_blank' : '_self'"
		>
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</a>

		<!-- item.externalUrl -->
		<a
			class="dropdown-item d-flex align-items-center"
			[ngClass]="item.classes"
			*ngIf="item.url && item.externalUrl"
			[href]="item.url"
			[target]="item.openInNewTab ? '_blank' : '_self'"
		>
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</a>

	} @else {
		<a
			class="dropdown-item d-flex align-items-center"
			[ngClass]="item.classes"
		>
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</a>

	}

	<ng-template #itemContent>
		<fa-icon [icon]="item.icon" *ngIf="item.icon"></fa-icon>
		<span [translate]="item.translate">{{ item.title }}</span>
	</ng-template>


</ng-container>
