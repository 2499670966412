import { CoreMenu } from '@core/types';

export const adminMenu: CoreMenu[] = [
	{
		id: 'home',
		title: 'Home',
		translate: 'MENU.HOME',
		type: 'item',
		icon: 'home',
		url: 'admin/home',
	},
	{
		id: 'utenti',
		title: 'Utenti',
		type: 'item',
		icon: 'users',
		url: 'admin/utenti',
	},
	{
		id: 'comunicazioni',
		title: 'Comunicazioni',
		type: 'item',
		icon: 'comments',
		url: 'admin/comunicazioni',
	},
	/*{
		id: 'programmi',
		title: 'Programmi',
		type: 'item',
		icon: 'file-invoice',
		url: 'programmi',
	},*/
];
