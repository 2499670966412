export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      ADMIN: 'Amministrazione',
      SENT_INVOICES: 'Fatture Inviate',
      CLIENTS: 'Clienti',
    }
  }
}
