import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CoreMediaService {
  currentMediaQuery: string;

  onMediaUpdate: BehaviorSubject<number> = new BehaviorSubject<number>(document.body.clientWidth);

  constructor() {
    // Set the defaults
    this.currentMediaQuery = '';

    // Initialize
    this._init();
  }

  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    window.onresize = e => {
      this.onMediaUpdate.next(document.body.clientWidth);
    };
  }
}
