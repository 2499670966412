import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		CoreDirectivesModule,
		CorePipesModule,
		NgSelectModule,
		NgbModule,
		FontAwesomeModule,
		NgxSpinnerModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		CoreDirectivesModule,
		CorePipesModule,
		NgSelectModule,
		NgbModule,
		FontAwesomeModule,
		NgxSpinnerModule,
	],
})
export class CoreCommonModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far);
	}
}
