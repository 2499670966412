import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthResponse } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class SegnalazioniService {
  constructor(private _http: HttpClient) {}

  inviaSegnalazione(data: {msgbody: string}): Observable<AuthResponse> {
    return this._http.post<AuthResponse>(`/msg/new`, data);
  }

}
