import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ContentHeader } from '../layout/components/content-header/content-header.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    template: '',
})
export class BaseComponent implements OnInit, OnDestroy {
    _unsubscribeAll: Subject<any>;
    contentHeader: ContentHeader;
    entityMap = [
        { code: '&#8217;', replace: "'"},
        { code: '&#8216;', replace: '‘' },
        { code: '&#8220;', replace: '“' },
        { code: '&#8221;', replace: '”' },
        { code: '&amp;', replace: '&' },
        { code: '&lt;', replace: '<' },
        { code: '&gt;', replace: '>' },
        { code: '&quot;', replace: '"' },
    ];

    constructor() {
        this._unsubscribeAll = new Subject<any>();

        this.contentHeader = {
            headerTitle: undefined,
            icon: undefined,
            actionButton: false,
            btn: { visible: false, disabled: false, textDisabled: undefined },
        };
    }

    setHeader(
        title: string,
        icon: IconProp | Array<IconProp>,
        btn: {
            visible: boolean;
            disabled: boolean;
            textDisabled: string;
        }
    ) {
        this.contentHeader.headerTitle = title;
        this.contentHeader.icon = icon;
        this.contentHeader.btn.visible = btn.visible;
        this.contentHeader.btn.disabled = btn.disabled;
        this.contentHeader.btn.textDisabled = btn.textDisabled;
    }

    setActionButtonHeader(visible: boolean) {
        this.contentHeader.actionButton = visible;
    }

    decode(string) {
        let found = true;
        while (found) {
            found = false;
            this.entityMap.forEach(item => {
                if (string.includes(item.code)) {
                    string = string.replace(item.code, item.replace);
                    found = true;
                }
            });
        }
        return string;
    }

    ngOnInit() {}

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
