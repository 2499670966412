import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse } from 'app/server/models';
import { Azienda } from 'app/server/models/azienda/azienda';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AziendaService {
	constructor(private _http: HttpClient) {}

	getAzienda(): Observable<Azienda> {
		return this._http.get<Azienda>(`/azienda`);
	}
}
