<div class='modal-header'>
	<h4 class='modal-title text-center mx-auto' id='modal-basic-title'>Invia Segnalazione</h4>
</div>

<div class='modal-body'>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header row">
					<h5 class="card-title col-md-12 mb-1 p-1 fs-5">Segnalaci eventuali inesattezze nelle informazioni presenti nella tua Area Riservata</h5>
				</div>
				<div class="card-body pb-1">
					<form [formGroup]="segnalazioneErroriForm">
						<div class="row mb-3">
							<div class="col-12">
								<label for="descrizioneErrore">Descrizione</label>
								<textarea id="descrizioneErrore" formControlName="msgbody" class="form-control" rows="4" placeholder="Descrivi l'errore riscontrato"></textarea>
							</div>
						</div>
						<div class='mb-2 mt-1 d-flex justify-content-end'>
							<button
								class='btn btn-primary float-end mt-1'
								[disabled]='loadingForm || !segnalazioneErroriForm.valid'
								(click)="inviaSegnalazione()"
							>
								<span *ngIf='loadingForm' class='spinner-border spinner-border-sm me-1'></span>Invia Segnalazione
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

	<div class='modal-footer'>
		<button type='button' class='btn btn-danger' (click)='activeModal.dismiss(false)'>Chiudi</button>
	</div>
</div>
