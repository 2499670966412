import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Documenti, DocumentiUtente } from 'app/server/models/polizza/documenti';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DocumentiService {
	constructor(private _http: HttpClient) {}

	getDoc(idDoc): Observable<Documenti> {
		return this._http.get<Documenti>(`/documenti/${idDoc}`);
	}

	getDocUser(keyopz: string): Observable<DocumentiUtente> {
		return this._http.get<DocumentiUtente>(`/doc/opz/bindata/${keyopz}`);
	}

	getDataCertificati(keycert: string): Observable<DocumentiUtente> {
		return this._http.get<DocumentiUtente>(`/doc/cert/bindata/${keycert}`);
	}

	getDataRegolamenti(keyreg: string): Observable<DocumentiUtente> {
		return this._http.get<DocumentiUtente>(`/doc/reg/bindata/${keyreg}`);
	}
}
