import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  template: `<div class="w-100 d-flex justify-content-center"><div [class]="class" (click)="btnClickedHandler($event)"><fa-icon [icon]="icon"></fa-icon></div></div>`,
})
export class BtnIconCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  diplayText: string;
  class: string;
  icon: IconProp;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  agInit(params: any): void {
    this.params = params;
    if (this.params.label) {
      this.diplayText = this.params.label;
    } else {
      this.diplayText = this.params.value;
    }

    if (this.params.class) {
      this.class = this.params.class;
    } else {
      this.class = 'btn btn-sm btn-primary mx-auto';
    }

    if (this.params.icon) {
      this.icon = this.params.icon;
    } else {
      this.icon = 'download';
    }

    // this.params.url = this.params.url.replace('{field}', this.params.data[this.params.field]);

    // let indexStart = 0;
    // const paramsName = [];
    // let tempUrl = this.params.url;
    // while (1) {
    //     indexStart = tempUrl.indexOf('{') + 1;
    //
    //     if (indexStart === 0) {
    //         break;
    //     }
    //
    //     let paramTemp = tempUrl.substr(indexStart);
    //     tempUrl = paramTemp;
    //
    //     paramTemp = paramTemp.substr(0, paramTemp.indexOf('}'));
    //     paramsName.push(paramTemp);
    // }
    //
    // for (let paramName of paramsName) {
    //     paramName = paramName.split('.');
    //
    //     let paramValue = undefined;
    //
    //     let first = true;
    //     for (const paramNamePath of paramName) {
    //         if (first) {
    //             paramValue = this.params.data[paramNamePath];
    //         } else {
    //             paramValue = paramValue[paramNamePath];
    //         }
    //         first = false;
    //     }
    //
    //     this.params.url = this.params.url.replace(`{${paramName}}`, this.params.data[paramName]);
    // }
  }

  btnClickedHandler($event) {
    this.params.onClick(this.params.data);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
    return true;
    // set value into cell again
  }
}
