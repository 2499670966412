import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/server/services';
import { JwtPayload } from '../models';
import { jwtDecode } from 'jwt-decode';
import { lastValueFrom } from 'rxjs';
import { UserTypeService } from '../services/user-type.service';

export const AuthGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const authenticationService = inject(AuthenticationService);
	const userTypeService = inject(UserTypeService);
	const _router = inject(Router);
	const currentUser = authenticationService.currentUserValue;
	const userType = userTypeService.userTypeObj.value;

	if (currentUser && userType === 'admin') {
		let currentTimestamp: number = +(new Date().getTime() / 1000).toFixed();

		if (currentTimestamp - jwtDecode<JwtPayload>(currentUser).nbf >= 300) {
			//il token è stato generato più di 5 min fa
			await lastValueFrom(authenticationService.refreshToken()).catch(() => {
				authenticationService.logout();
				_router.navigate(['/admin'], { queryParams: { returnUrl: state.url } });
			});
		}
		return true;
	} else if (currentUser && userType === 'azienda') {
		return true;
	}

	// not logged in so redirect to login page with the return url
	await _router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
	return false;
};
