import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { take, takeUntil, filter } from 'rxjs/operators';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { CoreConfigService } from '@core/services/config.service';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
	selector: 'vertical-menu',
	templateUrl: './vertical-menu.component.html',
	styleUrls: ['./vertical-menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
	coreConfig: any;
	menu: any;
	isCollapsed: boolean;
	isScrolled: boolean = false;

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 * @param {CoreMenuService} _coreMenuService
	 * @param {CoreSidebarService} _coreSidebarService
	 * @param {Router} _router
	 */
	constructor(
		private _coreConfigService: CoreConfigService,
		private _coreMenuService: CoreMenuService,
		private _coreSidebarService: CoreSidebarService,
		private _router: Router
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	@ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On Init
	 */
	ngOnInit(): void {
		// Subscribe config change
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
			this.coreConfig = config;
		});

		this.isCollapsed = this._coreSidebarService.getSidebarRegistry('menu').collapsed;

		// Close the menu on router NavigationEnd (Required for small screen to close the menu on select)
		this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(() => {
				if (this._coreSidebarService.getSidebarRegistry('menu')) {
					this._coreSidebarService.getSidebarRegistry('menu').close();
				}
			});

		// scroll to active on navigation end
		/*this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				take(1)
			)
			.subscribe(() => {
				setTimeout(() => {
					this.directiveRef.scrollToElement('.navigation .active', -180, 500);
				});
			});*/

		// Get current menu
		this._coreMenuService.onMenuChanged
			.pipe(
				filter(value => value !== null),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(() => {
				this.menu = this._coreMenuService.getCurrentMenu();
			});
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// Public Methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On Sidebar scroll set isScrolled as true
	 */
	/*onSidebarScroll(): void {
    if (this.directiveRef.position(true).y > 3) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }*/

	/**
	 * Toggle sidebar expanded status
	 */
	toggleSidebar(): void {
		this._coreSidebarService.getSidebarRegistry('menu').toggleOpen();
	}

	/**
	 * Toggle sidebar collapsed status
	 */
	toggleSidebarCollapsible(): void {
		// Get the current menu state
		this._coreConfigService
			.getConfig()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(config => {
				this.isCollapsed = config.layout.menu.collapsed;
			});

		if (this.isCollapsed) {
			this._coreConfigService.setConfig({ layout: { menu: { collapsed: false } } }, { emitEvent: true });
		} else {
			this._coreConfigService.setConfig({ layout: { menu: { collapsed: true } } }, { emitEvent: true });
		}
	}

	redirectToHome() {
		this._router.navigate(['/']);
	}
}
