import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreMenuModule } from '@core/components';
import { CoreCommonModule } from '@core/common.module';

import { VerticalMenuComponent } from 'app/layout/components/menu/vertical-menu/vertical-menu.component';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
	declarations: [VerticalMenuComponent],
	imports: [CoreMenuModule, CoreCommonModule, RouterModule, NgScrollbarModule],
	providers: [
		{
			provide: NG_SCROLLBAR_OPTIONS,
			useValue: {
				visibility: 'hover',
			},
		},
	],
	exports: [VerticalMenuComponent],
})
export class VerticalMenuModule {}
