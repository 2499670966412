import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import Swal, {SweetAlertIcon} from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private _toastrService: ToastrService) {
  }

  showToast(type: 'show' | 'success' | 'error' | 'info' | 'warning',
            title: string, message: string,
            positionClass: 'toast-top-left' | 'toast-top-center' | 'toast-top-right' | 'toast-top-full-width' | 'toast-bottom-left' | 'toast-bottom-center' | 'toast-bottom-right' | 'toast-bottom-full-width' = 'toast-bottom-center',
            closeButton = true,
            showProgress = false) {

    // Clear all toast
    this.clearToast();

    this._toastrService[type](message, title, {
      positionClass,
      toastClass: 'toast show ngx-toastr bg-white border-warning-toast-2 mb-4',
      closeButton
    });
  }

  clearToast() {
    this._toastrService.clear();
  }

  fireSwal(title = 'Confermi la cancellazione?', text = 'L\'operazione non potrà essere annullata!', icon: SweetAlertIcon = 'warning', okText = 'Confermo', cancelText = 'Annulla') {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      cancelButtonText: cancelText,
      confirmButtonText: okText,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ms-1'
      }
    });
  }

  fireSwalCestino(title = 'Confermi la cancellazione?', text = 'Il file verrà spostato nel cestino!', icon: SweetAlertIcon = 'warning') {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      cancelButtonText: 'Annulla',
      confirmButtonText: 'Confermo',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ms-1'
      }
    });
  }
}
