import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreTableComponent } from './core-table.component';
import { AgGridModule } from 'ag-grid-angular';
import { BtnCellEditRendererComponent, BtnCellRendererComponent } from './components';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '../../common.module';
import { DocumentiRendererComponent } from './components/documenti-render.component';
import { BtnIconCellRendererComponent } from './components/btn-icon-cell-renderer.component';

@NgModule({
  declarations: [CoreTableComponent, BtnCellRendererComponent, BtnCellEditRendererComponent, DocumentiRendererComponent, BtnIconCellRendererComponent],
  imports: [CommonModule, AgGridModule, NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, CoreCommonModule],
  //.withComponents([BtnCellRendererComponent, BtnCellEditRendererComponent])
  exports: [CoreTableComponent],
})
export class CoreTableModule {}
